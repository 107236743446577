import React from 'react'
import { motion } from 'framer-motion'
import { Button, Section, TitleSection, TextHeading, Container } from '../styles/Global.styled'
import { FaWhatsapp, FaRegEnvelope } from "react-icons/fa6";
import { fadeInRightVariant } from '../utils/Variants'

const Contato = () => {
  return (
    <Section as={motion.section} variants={fadeInRightVariant} initial="hidden" whileInView="visible" id="contato">
      <TitleSection size="title">CONTATO</TitleSection>
      <TextHeading size="h4">
        Para mais informações sobre atendimento ou serviços entre em contato pelo whatsapp ou nos envie um e-mail.
      </TextHeading>
      <TextHeading size="h4">
        (11) 97489-0609
      </TextHeading>
      <Container row>
        <Button
          color="whatsapp"
          href="https://wa.me/5511974890609?text=Olá,%20gostaria%20de%20mais%20informações%20para%20poder%20estar%20cuidando%20da%20estética%20do%20meu%20veículo"
          target="_blank"
        >
          <FaWhatsapp />
          WhatsApp
        </Button>
        <Button
          color="email"
          href="mailto:bahiadetailpolimento@gmail.com?subject=Orçamento&body=Olá, gostaria de mais informações para poder estar cuidando da estética do meu veículo"
          target="_blank"
        >
          <FaRegEnvelope />
          E-mail
        </Button>
      </Container>
    </Section>
  )
}

export default Contato