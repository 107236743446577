export const navLinks = [
  {
    id: 0,
    name: 'Início',
    href: '#inicio'
  },
  {
    id: 1,
    name: 'Sobre nós',
    href: '#sobre'
  },
  {
    id: 2,
    name: 'Serviços',
    href: '#servicos'
  },
  {
    id: 3,
    name: 'Contato',
    href: '#contato'
  },
  {
    id: 4,
    name: 'Benefícios',
    href: '#beneficios'
  }
]

export const especialidades = [
  {
    id: 1,
    title: "Especialização e Experiência",
    text: "Anos de atuação e reputação sólida."
  },
  {
    id: 2,
    title: "Atendimento Premium",
    text: "Prioridade e satisfação garantida."
  },
  {
    id: 3,
    title: "Tecnologia de Ponta",
    text: "Técnicas modernas para um acabamento perfeito."
  },
  {
    id: 4,
    title: "Rapidez e Eficiência",
    text: "Serviço rápido e de alta qualidade."
  },
]

export const valores = [
  {
    id: 1,
    title: "Qualidade",
    text: "Compromisso com a excelência utilizando produtos e técnicas de alta qualidade."
  },
  {
    id: 2,
    title: "Atendimento ao Cliente",
    text: "Satisfação e bem-estar dos clientes com um atendimento cordial, transparente e personalizado."
  },
  {
    id: 3,
    title: "Inovação",
    text: "Melhoria contínua e adoção de novas técnicas e tecnologias."
  },
  {
    id: 4,
    title: "Integridade",
    text: "Honestidade, ética e responsabilidade em todas as relações."
  },
]

export const services = [
  {
    id: 1,
    image: 'reparo-de-vidros.png',
    name: 'Reparo de Vidros',
    link: 'https://wa.me/5511974890609?text=Olá,%20gostaria%20de%20realizar%20um%20orçamento%20de%20reparo%20de%20vidros'
  },
  {
    id: 2,
    image: 'polimento-de-vidro.png',
    name: 'Polimento de Vidros',
    link: 'https://wa.me/5511974890609?text=Olá,%20gostaria%20de%20realizar%20um%20orçamento%20de%20polimento%20de%20vidros'
  },
]

export const beneficiosDetails = [
  {
    id: 1,
    color: 'green',
    image: 'map.png',
    title: 'ÓTIMA LOCALIZAÇÃO',
    subtitle: 'Nossa oficina é localizada no centro da cidade para sua conveniência!'
  },
  {
    id: 2,
    color: 'red',
    image: 'calendar.png',
    title: 'AGENDE SEU HORÁRIO',
    subtitle: 'Agende o melhor horário para seu atendimento e evite filas!'
  },
  {
    id: 3,
    color: 'blue',
    image: 'keys.png',
    title: 'BUSCAMOS SEU VEÍCULO',
    subtitle: 'Nossa equipe pode buscar seu veículo e entregar ao seu domicílio!'
  }
]

export const carousel = [
  {
    id: 1,
    image: 'vidro-antes.png',
    title: 'Recuperação de vidro trincado',
    subtitle: 'Antes'
  },
  {
    id: 2,
    image: 'vidro-depois.png',
    title: 'Recuperação de vidro trincado',
    subtitle: 'Depois'
  },
  {
    id: 3,
    image: 'polimento-antes.png',
    title: 'Polimento de vidro',
    subtitle: 'Antes'
  },
  {
    id: 4,
    image: 'polimento-durante.png',
    title: 'Polimento de vidro',
    subtitle: 'Durante'
  },
  {
    id: 5,
    image: 'polimento-depois.png',
    title: 'Polimento de vidro',
    subtitle: 'Depois'
  },
]