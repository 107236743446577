import React from 'react'
import Navbar from '../components/Navbar'
import Inicio from '../components/Inicio'
import Sobre from '../components/Sobre'
import Servicos from '../components/Servicos'
import Contato from '../components/Contato'
import Beneficios from '../components/Beneficios'
import Map from '../components/Map'
import Footer from '../components/Footer'
import Conectar from '../components/Conectar'
import { Main } from '../styles/Global.styled'
import CtaButton from '../components/CtaButton'

const Home = () => {
  return (
    <>
      <Navbar />
      <Main>
        <Inicio />
        <Sobre />
        <Servicos />
        <Contato />
        <Beneficios />
        <Map />
        <Conectar />
        <Footer />
        <CtaButton />
      </Main>
    </>
  )
}

export default Home