import styled from "styled-components";

export const SectionImage = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('bg-img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 8.5rem 6.25rem;
  width: 100%;
  min-height: 1024px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    padding: 2.5rem 1rem;
    min-height: unset;
  }
`