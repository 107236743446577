export const theme = {
  colors: {
    theme: '#161616',
    white: '#FFFFFF',
    primary: '#0C8CE9',
    green: '#098D6E',
    red: '#852121',
    blue: '#093E8D',
    whatsapp: '#53CF61',
    mail: '#EE534F',
    facebook: '#3C5A9A',
    instagram: 'linear-gradient(93deg, #F9D002 0%, #EA2A7E 49.9%, #7F3BB1 100%)',
  },
  fonts: {
    family: 'Inter',
    emphasis: 'Alfa Slab One',
    weight: {
      regular: 400,
      semibold: 600,
      bold: 700
    }
  },
  breakpoints: {
    mobile: '768px',
    tablet: '1024px',
    laptop: '1440px'
  }
}