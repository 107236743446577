import styled from "styled-components";

export const GridLayout = styled.div`
  display: grid;
  justify-content: start;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 2.5rem;
  margin: ${({ responsive }) => responsive ? '2rem 0 3rem 0' : '' };
  
  @media (max-width: ${({ theme }) => theme.breakpoints.laptop }) {
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
    width: 100%;
    justify-content: center;
    margin: ${({ responsive }) => responsive ? '1rem 0 2rem 0' : '' };
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #00000060;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 2.5rem;
  border-radius: .75rem;
  width: 100%;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    gap: .75rem;
    padding: 1rem;
  }
`

export const Title = styled.h2`
  font-size: 1.5rem;
  line-height: 156%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    font-size: 1.125rem;
  }
`

export const Text = styled.span`
  font-size: 1rem;
  line-height: 156%;
  opacity: .6;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    font-size: .875rem;
  }
`