import React from 'react'
import { FloatButton } from '../styles/Global.styled'
import { FaWhatsapp } from 'react-icons/fa6'

const CtaButton = () => {
  return (
    <FloatButton color="whatsapp" href='https://wa.me/5511974890609?text=Olá%20gostaria%20de%20realizar%20um%20orçamento' target="_blank">
      <FaWhatsapp />
      Fale conosco!
    </FloatButton>
  )
}

export default CtaButton