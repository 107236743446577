import styled from "styled-components";

export const Main = styled.main`
  background-color: ${({ theme }) => theme.colors.theme };
  color: ${({ theme }) => theme.colors.white };
  font-family: ${({ theme }) => theme.fonts.family }, sans-serif;
  padding-top: 103px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    padding-top: 73px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile }) {
    padding-top: 63px;
  }
`

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
  padding: 6.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    padding: 4rem 1rem;
    gap: 2.5rem;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ row }) => row ? 'row': 'column' }; 
  flex-wrap: ${({ row }) => row ? 'wrap': '' };
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  gap: ${({ gap }) => {
    switch(gap) {
      case '4':
        return '.25rem'
      
      case '8':
        return '.5rem'
      
      case '12':
        return '.75rem'
      
      case '24':
        return '1.5rem'

      case '32':
        return '2rem'

      case '40':
        return '2.5rem'

      case '80':
        return '5rem'

      default:
        return '1rem'
    }
  }};
`

export const TitleSection = styled.h2`
  width: ${({ header }) => header ? '190px' : '100%'};
  font-family: ${({ theme }) => theme.fonts.emphasis };
  font-weight: 400;
  line-height: 120% !important;
  text-align: center;
  font-size: ${({ size }) => {
    switch(size) {
      case 'header':
        return '1.875rem';
      
      case 'title':
        return '4rem';
      
      case 'subtitle':
        return '1.5rem';

      default:
        return '6rem';
    }
  }};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    width: 100%;
    font-size: ${({ size }) => {
      switch(size) {
        case 'header':
          return '1.75rem';
        
        case 'title':
          return '1.75rem';
        
        case 'subtitle':
          return '1.25rem';

        default:
          return '2rem';
      }
    }};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile }) {
    width: 100%;
    font-size: ${({ size }) => {
      switch(size) {
        case 'header':
          return '1.25rem';

        case 'title':
          return '1.75rem';
        
        case 'subtitle':
          return '1.25rem';

        default:
          return '2rem';
      }
    }};
  }
`

export const TextHeading = styled.span`
  text-align: center;
  line-height: 156%;
  font-weight: ${({ theme, weight }) => {
    switch(weight) {
      case 'semibold':
        return theme.fonts.weight.semibold

      case 'bold':
        return theme.fonts.weight.bold

      default:
        return theme.fonts.weight.regular
    }
  }};
  font-size: ${({ size }) => {
    switch(size) {
      case 'h2':
        return '4rem';

      case 'h3':
        return '2rem';
      
      case 'h4':
        return '1.375rem';
      
      case 'h5':
        return '1.25rem';

      default:
        return '1rem';
    }
  }};

  @media (max-width: 912px) {
    font-size: ${({ size }) => {
      switch(size) {
        case 'h2':
          return '1.25rem';

        case 'h3':
          return '1rem';

        case 'h4':
          return '.875rem';

        case 'h5':
          return '.875rem';

        default:
          return '.875rem';
      }
    }};
  }
`

export const Button = styled.a`
  background: ${({ theme, color }) => {
    switch(color) {
      case 'whatsapp':
        return theme.colors.whatsapp;

      case 'instagram':
        return theme.colors.instagram;

      case 'facebook':
        return theme.colors.facebook;
      
      case 'email':
        return theme.colors.mail;

      default:
        return theme.colors.primary;
    }
  }};
  text-decoration: none;
  border-radius: .5rem;
  padding: .75rem 1.5rem;
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .75rem;
  width: max-content;
  min-width: 224px;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    font-size: 1rem;
  }
`

export const FloatButton = styled(Button)`
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 2;
  border-radius: 128px;
  min-width: unset;
  font-size: 1.25rem;
  gap: .5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile }) {
    bottom: 2.5rem;
    right: 1rem;
  }
`

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
`