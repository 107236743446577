import React from 'react'
import { motion } from 'framer-motion'
import { Button, Section, TitleSection } from '../styles/Global.styled'
import { CardContainer, GridCards, Image, Title } from '../styles/CardService.styled'

import { FaArrowUpRightFromSquare } from "react-icons/fa6"

import { fadeInLeftVariant } from '../utils/Variants'
import { services } from '../utils/Data'
import Carousel from './Carousel'


const Servicos = () => {
  return (
    <Section as={motion.section} variants={fadeInLeftVariant} initial="hidden" whileInView="visible" id="servicos">
      <TitleSection size="title">SERVIÇOS</TitleSection>
      <Carousel />
      <GridCards>
        {services.map((service, id) => (
          <CardContainer key={id}>
            <Image src={service.image} draggable="false" />
            <Title>{service.name}</Title>
            <Button className="btn-services" href={service.link} target="_blank">
              Consultar
              <FaArrowUpRightFromSquare />
            </Button>
          </CardContainer>
        ))}
      </GridCards>
    </Section>
  )
}

export default Servicos