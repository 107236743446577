import React from 'react'
import { motion } from 'framer-motion'
import { SectionImage } from '../styles/Inicio.styled'
import { Button, Container, TextHeading, TitleSection } from '../styles/Global.styled'
import { fadeInLeftVariant } from '../utils/Variants'
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { Card, GridLayout, Text, Title } from '../styles/CardBlur.styled'
import { especialidades } from '../utils/Data'


const Inicio = () => {
  return (
    <SectionImage id="inicio">
      <Container as={motion.div} variants={fadeInLeftVariant} initial="hidden" whileInView="visible">
        <TitleSection>Reparo e Polimento de Vidros de Qualidade Superior!</TitleSection>
        <TextHeading size="h4">
          Atendemos Mauá e região do ABC Paulista, trazendo excelência para o seu carro. Seja para corrigir danos ou manter a estética, somos a escolha ideal para quem valoriza cada detalhe.
        </TextHeading>
        <TextHeading size="h4">Por que Bahia Detail?</TextHeading>
        <GridLayout>
          {especialidades.map((item, id) => (
            <Card key={id}>
              <Title>{item.title}</Title>
              <Text>{item.text}</Text>
            </Card>
          ))}
        </GridLayout>
        <Button href="https://wa.me/5511974890609?text=Olá%20gostaria%20de%20realizar%20um%20orçamento" target="_blank">
          Solicite um Orçamento Agora!
          <FaArrowUpRightFromSquare />
        </Button>
      </Container>
    </SectionImage>
  )
}

export default Inicio