import styled from "styled-components";

export const NavbarContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 1rem 6.25rem;
  background-color: ${({ theme }) => theme.colors.theme };
  color: ${({ theme }) => theme.colors.white };
  font-family: ${({ theme }) => theme.fonts.emphasis }, sans-serif;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.60);

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop }) {
    padding: 1rem 2rem;
    gap: 4rem;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    padding: 1.5rem 1rem 1rem 1rem;
  }
`

export const ListFlex = styled.ul`
  display: flex;
  gap: 2.5rem;
  list-style: none;
  font-size: 1.5rem;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop }) {
    font-size: 1.5rem;
    gap: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    display: none;
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white };
  transition: all .2s ease;

  &:hover {
    text-decoration: underline;
  }
`

export const LogoLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    width: 100%;
  }
`