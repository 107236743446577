import styled from "styled-components";

export const GridCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop }) {
    flex-direction: column;
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .75rem;
  width: 100%;
  max-width: 480px;
  height: 420px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile }) {
    height: 340px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

export const Icon = styled.img`
  width: 4rem;
  z-index: 2;
`

export const Title = styled.span`
  font-size: 1.875rem;
  font-weight: ${({ theme }) => theme.fonts.weight.bold };
  text-align: center;
  z-index: 2;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    font-size: 1.25rem;
  }
`