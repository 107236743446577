import React from 'react';
import { IFrame } from '../styles/Map.styled';
import { motion } from 'framer-motion';
import { fadeInBottomVariant } from '../utils/Variants';

const Map = () => {
  return (
    <IFrame
      as={motion.iframe}
      variants={fadeInBottomVariant}
      initial="hidden"
      whileInView="visible"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.4945005795544!2d-46.4516991!3d-23.6582677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce6947024a991d%3A0x5058ee25944f2b66!2sAv.%20Washington%20Luiz%2C%202797%20-%20Vila%20Magini%2C%20Mau%C3%A1%20-%20SP%2C%2009390-140!5e0!3m2!1spt-BR!2sbr!4v1719964230328!5m2!1spt-BR!2sbr"
      width="100%"
      height="380"
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    >
    </IFrame>
  );
};

export default Map;