import styled from "styled-components"

export const CarouselContainer = styled.div`
  display: flex;
  gap: 1.125rem;
  width: 100%;
  max-width: 800px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  padding-bottom: 1rem;
  &::-webkit-scrollbar {
    height: 12px;
  };
  
  &::-webkit-scrollbar-track {
    background: #4D4D4D;
    border-radius: 5rem;
  };
  
  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 5rem;
  };
`

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: .75rem;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
`

export const Image = styled.img`
  width: 340px;
  max-height: 520px;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    width: 600px !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile }) {
    width: 280px !important;
    max-height: 400px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h2`
  font-size: 1.375rem;
  font-weight: 700;
  letter-spacing: -0.4px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile }) {
    font-size: 1.125rem;
  }
`

export const Subtitle = styled.h4`
  font-size: 1.125rem;
  opacity: .5;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile }) {
    font-size: .875rem;
  }
`