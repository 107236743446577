import React from 'react'
import { motion } from 'framer-motion'
import { TitleSection, Image, Container, Section, TextHeading } from '../styles/Global.styled'
import { Card, GridLayout, Text, Title } from '../styles/CardBlur.styled'
import { fadeInLeftVariant, fadeInTopVariant } from '../utils/Variants'
import { valores } from '../utils/Data'

const Sobre = () => {
  return (
    <Section as={motion.section} variants={fadeInTopVariant} initial="hidden" whileInView="visible" id="sobre">
      <TitleSection size="title">SOBRE NÓS</TitleSection>
      <Container as={motion.div} variants={fadeInLeftVariant} initial="hidden" whileInView="visible">
        <TitleSection size="subtitle">Nossa história</TitleSection>
        <TextHeading size="h5">
          Macsuel, um jovem sonhador vindo da Bahia em 2009, começou sua jornada em uma funilaria sem qualquer experiência. Ao longo dos anos, dominou a preparação, pintura e polimento automotivo. Em 2015, casou-se e, com o apoio de sua esposa, iniciou trabalhos independentes aos finais de semana. A demanda crescente por polimentos levou Macsuel a abrir seu próprio negócio, inicialmente conhecido como Bahia Polimentos.
          <br />
          <br />
          Após cinco anos de aprendizado, Macsuel percebeu a necessidade de redefinir sua identidade e assim nasceu a Bahia Detail Estética Automotiva, oferecendo serviços estéticos automotivos de alta qualidade.
        </TextHeading>
        <TitleSection size="subtitle">Missão</TitleSection>
        <TextHeading size="h5">
          Proporcionar aos nossos clientes um serviço excepcional de estética automotiva, utilizando técnicas avançadas e produtos de alta qualidade, visando a satisfação e fidelização através de um atendimento personalizado e eficiente.
        </TextHeading>
        <TitleSection size="subtitle">Visão</TitleSection>
        <TextHeading size="h5">
          Ser reconhecidos como referência em estética automotiva na região, destacando-nos pela excelência nos serviços prestados, inovação constante e integridade em todas as operações.
        </TextHeading>
      </Container>
      <Container as={motion.div} variants={fadeInTopVariant} initial="hidden" whileInView="visible" gap="40">
        <TitleSection size="subtitle">Valores</TitleSection>
        <GridLayout>
          {valores.map((item, id) => (
            <Card key={id}>
              <Title>{item.title}</Title>
              <Text>{item.text}</Text>
            </Card>
          ))}
        </GridLayout>
        <Image src="image-1.png" />
      </Container>
    </Section>
  )
}

export default Sobre