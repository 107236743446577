import React from 'react'
import { FooterContainer } from '../styles/Footer.styled'
import { TextHeading } from '../styles/Global.styled'

const Footer = () => {
  return (
    <FooterContainer>
      <TextHeading>Copyright © 2024 Bahia Detail. Todos os direitos reservados.</TextHeading>
    </FooterContainer>
  )
}

export default Footer