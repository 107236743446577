import React from 'react'
import { CarouselContainer, CarouselItem, Container, Image, Subtitle, Title } from '../styles/Carousel.styled';
import { carousel } from '../utils/Data';

const Carousel = () => {
  return (
    <CarouselContainer>
      {carousel.map(item => (
        <CarouselItem key={ item.id }>
          <Image src={ item.image } />
          <Container>
            <Title>{ item.title }</Title>
            <Subtitle>{ item.subtitle }</Subtitle>
          </Container>
        </CarouselItem>
      ))}
    </CarouselContainer>
  );
}

export default Carousel;