import React from 'react'
import { TitleSection, Button, Container, Section, TextHeading } from '../styles/Global.styled'

import { FaInstagram, FaFacebook } from "react-icons/fa6";
import { motion } from 'framer-motion';
import { fadeInLeftVariant } from '../utils/Variants';

const Conectar = () => {
  return (
    <Section as={motion.section} variants={fadeInLeftVariant} initial="hidden" whileInView="visible">
      <TitleSection size="title">CONECTE-SE CONOSCO!</TitleSection>
      <TextHeading size="h4">
        Nossas plataformas sociais são o lugar ideal para
        manter-se atualizado e interagir conosco.
        <br />
        <br />
        Não perca tempo, junte-se à nossa comunidade agora mesmo!
      </TextHeading>
      <Container row>
        <Button color="instagram" href="https://www.instagram.com/bahia_detail" target='_blank'>
          <FaInstagram />
          Instagram
        </Button>
        <Button color="facebook" href="https://www.facebook.com/oBahiadetail?mibextid=ZbWKwL" target='_blank'>
          <FaFacebook />
          Facebook
        </Button>
      </Container>
    </Section>
  )
}

export default Conectar