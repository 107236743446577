import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 8rem;
  padding: 6.25rem 6.25rem 1rem 6.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    padding: 2rem 1rem;
    gap: 1.5rem;
  }
`