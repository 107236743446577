import React from 'react'
import { motion } from 'framer-motion'
import { Section, TitleSection, TextHeading, Container } from '../styles/Global.styled'
import { CardContainer, Grid, Image } from '../styles/CardBeneficios.styled'
import { fadeInTopVariant } from '../utils/Variants'
import { beneficiosDetails } from '../utils/Data'

const Beneficios = () => {
  return (
    <Section id="beneficios">
      <Container as={motion.div} variants={fadeInTopVariant} initial="hidden" whileInView="visible">
        <TitleSection size="title">BENEFÍCIOS</TitleSection>
        <Grid>
          {beneficiosDetails.map((beneficio, id) => (
            <CardContainer key={id} color={beneficio.color}>
              <Image src={beneficio.image} />
              <TitleSection size="subtitle">{beneficio.title}</TitleSection>
              <TextHeading size="h4">{beneficio.subtitle}</TextHeading>
            </CardContainer>
          ))}
        </Grid>
      </Container>
    </Section>
  )
}

export default Beneficios