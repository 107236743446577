import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-top: 12rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop }) {
    gap: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    margin-top: 0;
    flex-direction: column;
    gap: 1rem;
  }
`

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  position: relative;
  width: 430px;
  height: 360px;
  border-radius: 1rem;
  color: var(--white);
  background-color: ${({ theme, color }) => {
    switch(color) {
      case 'red':
        return theme.colors.red

      case 'green':
        return theme.colors.green

      case 'blue':
        return theme.colors.blue

      default:
        return theme.colors.primary
    }
  }};
  font-size: 1.5rem;
  padding: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop }) {
    width: 100%;
    height: 340px;
  }
`

export const Image = styled.img`
  position: absolute;
  top: -40%;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop }) {
    top: -28%;
    height: 8rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet }) {
    position: unset;
    height: 7.5rem;
  }
`