import React from 'react'
import { Link, ListFlex, LogoLink, NavbarContainer } from '../styles/Navbar.styled'
import { navLinks } from '../utils/Data'
import { Container, TitleSection } from '../styles/Global.styled'

const Navbar = () => {
  return (
    <NavbarContainer>
      <Container gap='80' row>
        <LogoLink href="#inicio">
          <TitleSection size="header" header>BAHIA DETAIL</TitleSection>
        </LogoLink>
        <ListFlex>
          {navLinks.map((link, id) => (
            <li key={id}>
              <Link href={link.href}>{link.name}</Link>
            </li>
          ))}
        </ListFlex>
      </Container>
    </NavbarContainer>
  )
}

export default Navbar